/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import manPurpleSweater from '../../images/heroes/man-in-purple-sweater.jpg'
import aetnaDirectAnimation from '../../images/illustrations/aetna-medicare-solutions.svg'
import { Phone } from 'emotion-icons/material'

import { StaticImage } from 'gatsby-plugin-image'
import CountdownTimer from '../../components/Countdown'

import {
  AvailabilityForm,
  Banner,
  Columns,
  Column,
  Hero,
  Link,
  LinkButton,
  Stack,
  Typography,
  VariableContent,
  useMapi,
  SplitContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/FooterPPC'
import header from '../../components/HeaderPPC'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()

  const brandyList = ['disclaimer-aet-5stepiterationlp']

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title: ' Aetna Medicare ',
          description: '',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/lp/5-steps-direct',
        promoCode: 'SAMPLEPROMO',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Aetna Medicare ',
    defaultRobots: 'nofollow,noindex',
    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={manPurpleSweater}
                  alt="elderly man in purple sweater sits on couch with notebook and pen while looking at laptop"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1" color="dark">
                    Enroll directly with Aetna<sup>®</sup> Medicare Direct{' '}
                  </Typography>

                  <div className="card bg-light">
                    <Typography variant="h4">
                      Find Medicare plans online{' '}
                    </Typography>
                    <div className="availability-form">
                      <AvailabilityForm
                        placeholderText="ZIP Code"
                        buttonText="Find Plans"
                        buttonColor="primary"
                        butttonBackgroundColor="black"
                        className="avail-form"
                      />
                    </div>
                  </div>
                </>
              }
            />
          </div>
        </div>

        <CountdownTimer />

        <VariableContent
          backgroundColor="lightGray"
          alignMainContent="center"
          mainContent={
            <Typography variant="h2">
              Enroll in Medicare in five easy steps
            </Typography>
          }
        >
          <Stack>
            <Columns>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-location.svg"
                    alt="location icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="dark">
                    Step 1
                  </Typography>
                  <Typography variant="body">
                    Enter your ZIP code to find plans nearby.
                  </Typography>
                </div>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-pill-bottles.svg"
                    alt="pill-bottles icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="dark">
                    Step 2
                  </Typography>
                  <Typography variant="body">
                    Share your preferred doctors and prescriptions.
                  </Typography>
                </div>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-cards-alt.svg"
                    alt="cards-alt icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="dark">
                    Step 3
                  </Typography>
                  <Typography variant="body">
                    Compare Medicare plans in your area.
                  </Typography>
                </div>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-laptop-check.svg"
                    alt="laptop-check icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="dark">
                    Step 4
                  </Typography>
                  <Typography variant="body">
                    Enroll in the Medicare plan you want.
                  </Typography>
                </div>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-thumbs-up.svg"
                    alt="thumbs-up icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="dark">
                    Step 5
                  </Typography>
                  <Typography variant="body">
                    Finish your Medicare enrollment.
                  </Typography>
                </div>
              </Column>
              <Column className="card center-content" backgroundColor="primary">
                <Link to="https://www.sunfirematrix.com/app/consumer/clearlink/18572496/ ">
                  <div className="top-content">
                    <StaticImage
                      src={'../../images/icons/white-checklist.svg'}
                      alt="chekclist icon"
                      layout="fixed"
                      className="product-icons"
                    />
                    <Typography variant="h5" color="light">
                      Step 6
                    </Typography>
                    <Typography variant="body" color="light">
                      Get Started
                    </Typography>
                  </div>
                </Link>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <Banner
          backgroundColor="Primary"
          className="cta-banner column-desktop"
          mainContent={
            <>
              <Typography
                variant="h5"
                style={{ color: 'white', marginBottom: '5px' }}
              >
                Talk to a Licensed Agent
              </Typography>
              <Typography
                variant="body"
                style={{ color: 'white', marginBottom: '10px' }}
              >
                TTY: 711, available 24/7
              </Typography>
              <LinkButton
                variant="feature"
                to={`tel:${rotatedNumber}`}
                color="light"
                className="margin-x-auto"
                leftIcon={<Phone />}
                requestId={require}
              >
                {rotatedNumber}
              </LinkButton>
            </>
          }
        />

        <SplitContent
          alignImageToBottom={false}
          image={
            <div className="gif-animation">
              <img
                src={aetnaDirectAnimation}
                alt="GIF of Aenta Medicare Solutions"
              />
            </div>
          }
          mainContent={
            <>
              <Typography variant="h2">
                Enrolling in Medicare shouldn’t be complicated
              </Typography>
              <Typography variant="body">
                The experts at Aetna® Medicare Direct are here to help. Talk to
                a licensed agent who can answer Medicare questions you have by
                calling <Link to={`tel:${rotatedNumber}`}>{rotatedNumber}</Link>{' '}
                (TTY:711) today.
              </Typography>
              <div className="card bg-light box-shadow">
                <Typography variant="h4">
                  Find Medicare plans online{' '}
                </Typography>
                <div className="availability-form">
                  <AvailabilityForm
                    placeholderText="ZIP Code"
                    buttonText="Find Plans"
                    buttonColor="primary"
                    butttonBackgroundColor="black"
                    className="avail-form"
                  />
                </div>
              </div>
            </>
          }
        />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
